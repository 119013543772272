import React from 'react';
import Modal from "@cloudscape-design/components/modal";
import Box from "@cloudscape-design/components/box";
import Button from "@cloudscape-design/components/button";
import SpaceBetween from "@cloudscape-design/components/space-between";
import TelegramLoginButton from 'telegram-login-button';

export type SigninModalProps = {
  visible: boolean;
  setVisible: Function;
  onSignin: Function;
};

const SigninModal = (props: SigninModalProps) => {
  const {visible, setVisible, onSignin} = props;
  return (
    <Modal
      onDismiss={() => setVisible(false)}
      visible={visible}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => setVisible(false)}>Cancel</Button>
          </SpaceBetween>
        </Box>
      }
      header="Sign in"
    >
      <TelegramLoginButton dataOnauth={onSignin} botName="SparkyPlaceBot" />
    </Modal>
  );
};
export default SigninModal;