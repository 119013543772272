import React from 'react';
import Flashbar, { FlashbarProps } from "@cloudscape-design/components/flashbar";

export type LocationFlashProps = {
  items: FlashbarProps.MessageDefinition[];
}
const LocationFlash = (props: LocationFlashProps) => {
  const {items} = props;
  return (
    <Flashbar items={items} />
  );
};

export default LocationFlash;