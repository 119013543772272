import React from 'react';
import Button from "@cloudscape-design/components/button";
import Form from "@cloudscape-design/components/form";
import FormField from "@cloudscape-design/components/form-field";
import Header from "@cloudscape-design/components/header";
import Input from "@cloudscape-design/components/input";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Spinner from '@cloudscape-design/components/spinner';

export type LocationFormProps = {
  loading: boolean;
  status: string;
  setStatus: Function;
  emote: string;
  setEmote: Function;
  handleOnSubmit: Function;
}
const LocationForm = (props: LocationFormProps) => {
  const { loading, status, setStatus, emote, setEmote, handleOnSubmit } = props;

  return (
    <form onSubmit={e => e.preventDefault()}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="primary" onClick={(event) => handleOnSubmit(event)}>Submit</Button>
          </SpaceBetween>
        }
        header={
          <Header variant="h2">
            Set location
          </Header>
        }
      >
        {loading ?
          <Spinner size='large' /> :
          <SpaceBetween direction="vertical" size="l">
            <FormField label="Status">
              <Input value={status} onChange={({ detail }) => setStatus(detail.value)} placeholder={"A word or two..."} />
            </FormField>
            <FormField label="Emote">
              <Input value={emote} onChange={({ detail }) => setEmote(detail.value)} placeholder={"😍 😜 😂 😛"} />
            </FormField>
          </SpaceBetween>
        }
      </Form>
    </form>
  );
};
export default LocationForm;